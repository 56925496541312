import { useEffect } from 'react'
import aa from 'search-insights'

const SearchInsights = () => {
  useEffect(() => {
    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
      useCookie: true
    })
  }, [])

  return null
}

export default SearchInsights
